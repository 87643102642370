import React, { Fragment } from "react"
import { Text } from "./Text"
import { Media as MediaComponent } from "./Media"
import Card from "../Card"
import { Flex } from "rebass"
import { useResponsive } from "../helpers/breakpoints"
import PropTypes from "prop-types"
import { MOBILE_MAX } from "../helpers/breakpoints"

export const FeatureCallout = ({
  mediaPosition = "right",
  headline,
  subHeadline,
  Copy,
  onCard,
  Cta,
  Media,
  shouldShowMediaOnMobile,
}) => {
  const { isMobile } = useResponsive()

  const flexDirection = isMobile ? "column" : "row"

  return (
    <Card
      css={`
        ${onCard
          ? `margin: 0 10px;
        color: white;
        background-image: linear-gradient(
          45deg,
          rgb(127, 212, 243),
          rgb(57, 151, 225)
        );`
          : `background: transparent;
             box-shadow: none;
            `}
      `}
    >
      <Flex
        px={50}
        css={`
          align-items: center;
          justify-content: space-between;
          flex-direction: ${flexDirection};
          @media (max-width: ${MOBILE_MAX}px) {
            flex-wrap: wrap;
          }
        `}
      >
        {mediaPosition === "right" ? (
          <Fragment>
            <Text
              headline={headline}
              subHeadline={subHeadline}
              Copy={Copy}
              mediaPosition={mediaPosition}
              Cta={Cta}
            />
            {(!isMobile || shouldShowMediaOnMobile) && (
              <MediaComponent Media={Media} />
            )}
          </Fragment>
        ) : (
          <Fragment>
            {(!isMobile || shouldShowMediaOnMobile) && (
              <MediaComponent Media={Media} />
            )}
            <Text
              headline={headline}
              subHeadline={subHeadline}
              Copy={Copy}
              mediaPosition={mediaPosition}
              Cta={Cta}
            />
          </Fragment>
        )}
      </Flex>
    </Card>
  )
}

FeatureCallout.propTypes = {
  /** Enum for choosing which side the media is displayed on */
  mediaPosition: PropTypes.oneOf(["left", "right"]).isRequired,
  /**  Text to display */
  headline: PropTypes.string.isRequired,
  /**  Text to display */
  subHeadline: PropTypes.string,
  /**  Text to display */
  Copy: PropTypes.node,
  /** Render prop for CTA */
  Cta: PropTypes.func,
  /** Render prop for Media */
  Media: PropTypes.func,
  /** Boolean for whether to render the Case study on a Card or not. */
  onCard: PropTypes.bool,
  shouldShowMediaOnMobile: PropTypes.bool,
}
