import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import Carousel from "nuka-carousel";
import Bars from "../Slider/Bars";

const IMG_HEIGHT = 200;

export const ImageSlide = ({ Media }) => (
  <Flex
    css={`
      width: 100%;
      height: ${IMG_HEIGHT}px;
      justify-content: space-around;
    `}
  >
    {Media.map(M => (
      <M style={{ maxHeight: IMG_HEIGHT }} />
    ))}
  </Flex>
);

ImageSlide.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired
};

export const ImageSlider = ({ Media }) => {
  const pageCount = Math.ceil(Media.length / 3);
  // Make a copy of the array because splice() destroys it
  const MediaCopy = [...Media];
  const slides = Array.from(Array(pageCount)).map(i => (
    <ImageSlide Media={[...MediaCopy.splice(0, 3)]} key={i} />
  ));

  return (
    <Flex
      css={`
        height: ${IMG_HEIGHT + 30}px;
        padding: 0 3px;
        overflow: hidden;
      `}
    >
      <Carousel
        frameOverflow="visible"
        wrapAround={true}
        renderCenterLeftControls={() => null}
        renderCenterRightControls={() => null}
        renderBottomCenterControls={({
          goToSlide,
          slideCount,
          currentSlide
        }) => (
          <Bars
            goToSlide={goToSlide}
            slideCount={slideCount}
            currentSlide={currentSlide}
          />
        )}
      >
        {slides}
      </Carousel>
    </Flex>
  );
};

ImageSlider.propTypes = {
  /** Image sources to be displayed */
  images: PropTypes.arrayOf(PropTypes.string).isRequired
};
