import React from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { colors } from "../helpers/styles";

const sizes = {
  empty: "0",
  small: "170px;",
  large: "500px;",
};

const colorMapping = {
  Primary1: colors.primary,
  Primary2: colors.primary2,
  Primary3: colors.primary3,
  Secondary1: colors.secondary,
  Secondary2: colors.secondary2,
  Secondary3: colors.secondary3,
  Tertiary1: colors.tertiary,
  Tertiary2: colors.tertiary2,
  Tertiary3: colors.tertiary3,
};

export const Hero = ({
  children,
  size = "large",
  backgroundImage = null,
  bg = null,
  ...props
}) => (
  <Flex
    width="100%"
    css={`
      min-width: 90vw;
      min-height: ${sizes[size]};
      background: ${colorMapping[bg] || colors.primary3};
      ${backgroundImage && `background-image: url(${backgroundImage});`}
      background-size: cover;
      justify-content: center;
      overflow: hidden;
      padding-top: 200px;
    `}
    {...props}
  >
    {children}
  </Flex>
);

export default Hero;

Hero.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(["small", "large"]),
  bg: PropTypes.string,
};
