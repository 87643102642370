import React, { useContext, useState } from "react";
import { Flex, Heading, Text } from "rebass";
import Input from "../Input";
import Button from "../Button";
import checkEmailForErrors from "../helpers/checkEmailForErrors";
import { EmailContext } from "./EmailContext";
import { colors } from "../helpers/styles";

export const EmailModal = ({ onSuccess }) => {
  const [error, setError] = useState(null);
  const { email, setEmail } = useContext(EmailContext);
  const validateEmail = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const emailValidationResponse = checkEmailForErrors(email || "");

    if (emailValidationResponse === "business") {
      setError(null);
      onSuccess();
    } else {
      setError("Please enter a valid business email address");
    }
  };

  return (
    <form onSubmit={validateEmail}>
      <Flex
        css={`
          height: 400px;
          min-width: 300px;
        `}
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Heading>Request A Demo</Heading>
        <Input
          placeholder="Your Email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        {error && <Text color={colors.secondary}> {error}</Text>}
        <Button>Request Demo</Button>
      </Flex>
    </form>
  );
};

export default EmailModal;
