import React from "react"
import { Flex, Box, Heading, Text } from "rebass"
import { useResponsive } from "../helpers/breakpoints"
import { colors } from "../helpers/styles"

export const HeroColumns = ({
  headline,
  Copy,
  style,
  Cta,
  Media,
  children,
}) => {
  const { isMobile } = useResponsive()
  const hasMedia = Media && Media() && !isMobile
  const textAlignment = hasMedia ? "left" : "center"

  return (
    <Flex
      mx={42}
      p={15}
      css={`
        max-width: 1140px;
        align-items: center;
        justify-content: space-around;
        text-align: ${textAlignment};
        padding: 50px;
      `}
      flexDirection={hasMedia ? "row" : "column"}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={hasMedia ? 2 / 5 : 1}
        css={`
          ${style}
          min-width: 50%;
          padding-right: 50px;
        `}
      >
        <h1>{headline}</h1>
        <Box mb={30} color={colors.desaturatedBlack}>
          <Copy />
        </Box>
        {Cta && (
          <Cta style={{ alignSelf: hasMedia ? "flex-start" : "center" }} />
        )}
      </Flex>
      {hasMedia && <Media />}
      {children}
    </Flex>
  )
}
