import React from "react"
import { Flex, Box } from "rebass"
import { colors } from "../helpers/styles"

const positions = {
  center: "center",
  right: "flex-end",
  left: "flex-start",
}

export const SectionHeader = ({ headline, Cta, position = "center", Copy }) => {
  return (
    <Flex
      px={4}
      css={`
        width: 100%;
        margin: auto;
        flex-direction: column;
        align-items: ${positions[position.toLowerCase()]};
        flex-wrap: wrap;
        text-align: ${position.toLowerCase()};
        color: ${colors.desaturatedBlack};
      `}
    >
      {headline && headline.length && <h2>{headline}</h2>}
      <Box mt={3}>
        <Copy />
      </Box>
      <Box
        css={`
          max-width: 300px;
        `}
      >
        <Cta />
      </Box>
    </Flex>
  )
}
