import React from "react"
import PropTypes from "prop-types"
import { Flex, Heading, Text } from "rebass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { iconize } from "../helpers/icons"
import { colors } from "../helpers/styles"

export const FeatureHighlight = ({
  headline,
  copy,
  icon,
  Media,
  Link,
  url,
}) => {
  let defaultHeight = 260
  if (!headline) defaultHeight -= 40
  if (!copy) defaultHeight -= 140
  const copyLines = copy ? copy.split(/<br \/>/g) : []

  const hasMedia = !!(Media && Media())
  const hasLink = !!url

  return (
    <Flex
      css={`
        max-width: 350px;
        min-height: ${defaultHeight}px;
      `}
      mx={1}
      px={2}
      alignItems="center"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Flex alignItems="center" flexDirection="column">
        {hasMedia && !hasLink && (
          <Media style={{ height: "72px", width: "100%" }} />
        )}
        {hasMedia && hasLink && (
          <Link to={url}>
            <Media style={{ height: "72px", width: "100%" }} />
          </Link>
        )}
        {!hasMedia && icon && !hasLink && (
          <FontAwesomeIcon
            css={`
              color: ${colors.primary2};
              margin: 0 auto;
              position: relative;
            `}
            style={{ color: colors.primary2 }}
            icon={iconize(icon)}
            size="4x"
          />
        )}
        {!hasMedia && icon && hasLink && (
          <Link to={url}>
            <FontAwesomeIcon
              css={`
                color: ${colors.primary2};
                margin: 0 auto;
                position: relative;
              `}
              style={{ color: colors.primary2 }}
              icon={iconize(icon)}
              size="4x"
            />
          </Link>
        )}

        {hasLink && (
          <Link to={url}>
            <Heading textAlign="center" mt={3} fontSize="24px" fontWeight="600">
              {headline}
            </Heading>
          </Link>
        )}
        {!hasLink && (
          <Heading textAlign="center" mt={3} fontSize="24px" fontWeight="600">
            {headline}
          </Heading>
        )}

        {hasLink && (
          <Link to={url}>
            <Text mt="20px" fontWeight="400" textAlign="center">
              {copyLines.map(c => (
                <React.Fragment key={c}>
                  {c}
                  <br />
                </React.Fragment>
              ))}
            </Text>
          </Link>
        )}
        {!hasLink && (
          <Text mt="20px" fontWeight="400" textAlign="center">
            {copyLines.map(c => (
              <React.Fragment key={c}>
                {c}
                <br />
              </React.Fragment>
            ))}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

FeatureHighlight.propTypes = {
  /**  Text to display */
  headline: PropTypes.string,
  /**  Text to display */
  copy: PropTypes.string,
  /** The icon name */
  icon: PropTypes.string.isRequired,
  url: PropTypes.string,
  Link: PropTypes.node,
  Media: PropTypes.node,
}
