import React from "react"
import { Helmet } from "react-helmet"

export const TrackingCodes = () => {
  return (
    <Helmet>
      {/* Anti-flicker snippet (recommended) */}
      <style>{`.async-hide { opacity: 0 !important}`}</style>
      <script>
        {`(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
          h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
          (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
          })(window,document.documentElement,'async-hide','dataLayer',4000,
          {'GTM-K26T69C':true});`}
      </script>

      {/* Google Tag Manager */}
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5G3S7GB');`}
      </script>

      {/* Initialize Bizible */}
      <script
        type="text/javascript"
        src="//cdn.bizible.com/scripts/bizible.js"
        async
      ></script>
    </Helmet>
  )
}
