import React from "react"
import PropTypes from "prop-types"
import Card from "../Card"

export const HeroImage = ({ Media }) => (
  <Card
    css={`
      height: 500px;
      width: 640px;
      position: relative;
      overflow: hidden;
      background: transparent;
    `}
  >
    <Media
      objectFit="contain"
      style={{
        objectFit: "contain",
        height: "100%",
      }}
    />
  </Card>
)

HeroImage.propTypes = {
  Media: PropTypes.node,
}
