import React from "react";
import { colors } from "../helpers/styles";

const Chevron = () => (
  <span
    css={`
      background: transparent;
      position: absolute;
      width: 0;
      height: 0;
      clear: both;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid ${colors.desaturatedWhite};
      transform: translateY(12px) translateX(50px);
      margin-top: 10px;
    `}
  />
);

export default Chevron;
