import React, { useState, useContext } from "react";
import { Flex, Heading } from "rebass";
import Input from "../Input";
import Button from "../Button";
import Select from "../Select";
import { useScript } from "../helpers/hooks";
import { EmailContext } from "./EmailContext";
import chilipiper from "../helpers/chilipiper";

const emptyFunc = () => null;

export const SurveyModal = ({
  onSuccess = emptyFunc,
  onFailure = emptyFunc,
}) => {
  const [marketoScriptLoaded, marketoError] = useScript(
    "https://app-ab34.marketo.com/js/forms2/js/forms2.min.js"
  );

  const [chilipiperScriptLoaded, chilipiperError] = useScript(
    "https://js.chilipiper.com/marketing.js"
  );

  const loaded = marketoScriptLoaded && chilipiperScriptLoaded;

  const { email } = useContext(EmailContext);
  const [form, setForm] = useState(null);
  const [fields, setFields] = useState({
    email: email,
    firstName: "",
    lastName: "",
    company: "",
    industry: "",
    CP_Company_Size__c: "",
    Influencers_Worked_With__c: "",
  });

  const handleScriptLoad = () => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    MktoForms2.loadForm(
      "//app-ab34.marketo.com",
      "982-DON-266",
      1010,
      (form) => {
        form.onSuccess((v) => {
          return false;
        });
        setForm(form);
      }
    );
  };

  const handleChange = (event) => {
    setFields({
      ...fields,
      [event.target.name]: event.target.value,
    });
  };

  const validateForm = () => {
    return Object.values(fields)
      .map((val) => !!val)
      .every((field) => field === true);
  };

  const submitForm = (event) => {
    event.preventDefault();
    const validated = validateForm();

    if (!validated) return onFailure();

    form.addHiddenFields({
      Email: fields.email,
      FirstName: fields.firstName,
      LastName: fields.lastName,
      Company: fields.company,
      Industry: fields.industry,
      CP_Company_Size__c: fields.CP_Company_Size__c,
      Influencers_Worked_With__c: fields.Influencers_Worked_With__c,
    });
    form.submit();
    chilipiper(fields);
    onSuccess();
  };

  // this should cause this to load the marketo form only once.
  if (loaded && form === null) handleScriptLoad();

  return (
    <div>
      {!loaded && <Heading>Loading Form...</Heading>}
      {loaded && (
        <Flex
          css={`
            height: auto;
            min-width: 300px;
          `}
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Heading>Request A Demo</Heading>
          <form onSubmit={submitForm}>
            <Input
              placeholder="First Name"
              name="firstName"
              value={fields.firstName}
              onChange={handleChange}
            />
            <Input
              placeholder="Last Name"
              name="lastName"
              value={fields.lastName}
              onChange={handleChange}
            />
            <Input
              placeholder="Company"
              name="company"
              value={fields.company}
              onChange={handleChange}
            />
            <Select
              label="Industry"
              placeholder="Select an industry"
              name="industry"
              onChange={handleChange}
              options={[
                { value: "agency", text: "Ad Agency" },
                { value: "beauty", text: "Beauty and Cosmetics" },
                { value: "electronics", text: "Electronics/Software" },
                { value: "entertainment", text: "Entertainment" },
                { value: "fashion", text: "Fashion" },
                { value: "financial", text: "Financial Services" },
                { value: "fitness", text: "Fitness and Health" },
                { value: "food", text: "Food and Beverage" },
                { value: "home", text: "Home and Garden" },
                { value: "hospitality", text: "Hospitality" },
                { value: "lifestyle", text: "Lifestyle" },
                { value: "pets", text: "Pets" },
                { value: "travel", text: "Travel" },
                { value: "other", text: "Other" },
              ]}
            />
            <Select
              label="How many people work at your company"
              placeholder="Select a range..."
              name="CP_Company_Size__c"
              onChange={handleChange}
              options={[
                { value: "1-20", text: "1-20" },
                { value: "21-50", text: "21-50" },
                { value: "51-250", text: "51-250" },
                { value: "251-500", text: "251-500" },
                { value: "501+", text: "501+" },
              ]}
            />
            <Select
              label="Number of influencers you’ve worked with in the past 6 months"
              name="Influencers_Worked_With__c"
              onChange={handleChange}
              placeholder="Select a range..."
              options={[
                { value: "None", text: "None" },
                { value: "1-5", text: "1-5" },
                { value: "6-20", text: "6-20" },
                { value: "21+", text: "21+" },
              ]}
            />
            <Button>Request Demo</Button>
          </form>
        </Flex>
      )}
    </div>
  );
};

export default SurveyModal;
