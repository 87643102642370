import React from "react";
import Card from "../Card";
import { Box, Flex } from "rebass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconize } from "../helpers/icons";
import { colors } from "../helpers/styles";
import { dropShadow } from "../helpers/styles";

export const Plan = ({ title, subtitle, Cta, introText, items, ...props }) => {
  return (
    <Card
      css={`
        padding: 25px;
        max-width: 370px;
        text-align: center;
        box-shadow: ${dropShadow};
      `}
      width={1 / 3}
      {...props}
    >
      <Flex flexDirection="column" justifyContent="center">
        <h2>{title}</h2>
        <Box
          css={`
            width: 220px;
            margin-bottom: 25px;
            align-self: center;
            opacity: 0.5;
          `}
        >
          <>{subtitle}</>
        </Box>
        {Cta && <Cta size="sm" />}
        {introText && (
          <Box
            css={`
              opacity: 0.5;
              margin: 25px 0;
            `}
          >
            {introText}
          </Box>
        )}
        {items.length ? (
          <Box
            css={`
              text-align: left;
            `}
          >
            {items.map((item) => {
              return (
                <Flex
                  css={`
                    padding: 10px 0;
                    border-bottom: 2px solid ${colors.desaturatedLight};
                  `}
                  alignItems="center"
                >
                  <FontAwesomeIcon
                    color={colors.primary}
                    icon={iconize("check")}
                  />
                  <Box
                    css={`
                      display: inline-block;
                      margin-left: 10px;
                    `}
                  >
                    {item.item}
                  </Box>
                </Flex>
              );
            })}
          </Box>
        ) : null}
      </Flex>
    </Card>
  );
};
