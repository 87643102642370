import React from "react";
import { Text } from "rebass";

export const NavLink = ({ weight, color, Link, ...props }) => (
  <Link to={props.to}>
    <Text
      px={2}
      py={1}
      color="inherit"
      css={`
        display: block;
        font-weight: ${weight || "bold"};
        color: ${color || "black"};
        ${props.size ? `font-size: ${props.size}` : ""}
      `}
      {...props}
    >
      {props.text}
    </Text>
  </Link>
);
