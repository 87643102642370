import styled from "styled-components";

export const Input = styled.input`
  border: none;
  border-bottom: 1px solid #1a1818;
  letter-spacing: 2px;
  font-weight: 700;
  width: 100%;
  margin-bottom: 25px;
`;
