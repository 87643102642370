import React from "react";
import { Flex, Box } from "rebass";
import { imageStyle, containerCss } from "./sharedStyles";
import { useResponsive } from "../helpers/breakpoints";
import fades from "./animations";

export const ThreePictureCollage = ({ Media: [One, Two, Three] }) => {
  const { isMobile } = useResponsive();

  if (isMobile)
    return (
      <Box
        css={`
          position: relative;
          width: 100%;
          animation: ${fades.right};
          ${containerCss}
        `}
      >
        <Two style={imageStyle} />
      </Box>
    );

  return (
    <Box
      css={`
        display: inline-block;
        width: 558px;
        height: 600px;
        position: relative;
      `}
    >
      {One && (
        <Flex
          css={`
            width: 180px;
            height: 180px;
            right: 30px;
            top: -10px;
            z-index: 0;
            animation: ${fades.right};
            ${containerCss}
          `}
        >
          <One style={imageStyle} />
        </Flex>
      )}
      {Two && (
        <Flex
          css={`
            width: 380px;
            height: 380px;
            top: 80px;
            z-index: 1;
            animation: ${fades.up};
            ${containerCss}
          `}
        >
          <Two style={imageStyle} />
        </Flex>
      )}
      {Three && (
        <Flex
          css={`
            width: 250px;
            height: 250px;
            bottom: 0;
            right: 110px;
            z-index: 2;
            animation: ${fades.right};
            ${containerCss}
          `}
        >
          <Three style={imageStyle} />
        </Flex>
      )}
    </Box>
  );
};

export default ThreePictureCollage;
