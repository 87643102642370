import React from "react";
import Card from "../Card";
import { Flex } from "rebass";

export const ImageCard = props => {
  const { Media, ...rest } = props;
  return (
    <Card
      css={`
        overflow: hidden;
      `}
    >
      <Flex
        css={`
          object-fit: contain;
          display: block;
          width: 100%;
        `}
        {...rest}
      >
        <Media
          style={{
            width: "100%",
            height: "100%",
            display: "block",
            objectFit: "contain"
          }}
        />
      </Flex>
    </Card>
  );
};
