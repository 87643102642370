/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang = "en", meta, title, seoFields, url }) {
  const { site, shareImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        shareImage: file(relativePath: { regex: "/share-image/" }) {
          publicURL
        }
      }
    `
  )

  const websiteUrl = typeof window !== "undefined" ? window.location.href : ""
  const metaDescription = description || site.siteMetadata.description
  const imageUrl = `${websiteUrl}${shareImage.publicURL}`
  let noindex = {}
  if (seoFields.noindex) noindex = { name: "robots", content: "noindex" }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seoFields.title || title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: description || metaDescription,
        },
        {
          property: `og:title`,
          content: title || title,
        },
        {
          property: `og:description`,
          content: description || metaDescription,
        },
        {
          property: `og:type`,
          content: seoFields.type || `website`,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          name: `twitter:card`,
          content: description || `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title || title,
        },
        {
          name: `twitter:description`,
          content: description || metaDescription,
        },
        {
          name: `twitter:image`,
          content: imageUrl,
        },
        noindex,
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
  seoFields: {},
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  seoFields: PropTypes.object,
}

export default SEO
