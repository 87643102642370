import React from "react"
import PropTypes from "prop-types"
import { Flex, Image, Box } from "rebass"
import TopRow from "./TopRow"
import BottomRow from "./BottomRow"
import { useResponsive } from "../helpers/breakpoints"
import { MobileNav } from "./MobileNav"
import Container from "../Container"

export const Navbar = ({ topRow, bottomRow, logo, Link, ...props }) => {
  const { isMobile } = useResponsive()
  if (isMobile)
    return (
      <Flex width="100%">
        <Flex
          css={`
            margin-top: 30px;
            margin-left: 30px;
            height: 50px;
            position: absolute;
            width: 200px;
          `}
        >
          <Link to="/">
            <Image src={logo || "https://via.placeholder.com/200x50"} />
          </Link>
        </Flex>
        <MobileNav Link={Link} items={[...topRow, ...bottomRow]} />
      </Flex>
    )
  return (
    <Box
      css={`
        position: absolute;
        width: 100%;
      `}
    >
      <Container>
        <Flex
          width="100%"
          px={41}
          css={`
            height: 110px;
            flex-wrap: wrap;
            jusify-content: space-between;
            align-content: space-between;
            z-index: 10;
          `}
          {...props}
        >
          <TopRow row={topRow} Link={Link} />
          <Flex
            css={`
              height: 60px;
              justify-content: space-between;
              align-items: center;
            `}
            width="100%"
          >
            <Flex
              css={`
                height: 50px;
                width: 300px;
              `}
            >
              <Link
                to="/"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Image src={logo || "https://via.placeholder.com/200x50"} />
              </Link>
            </Flex>
            <BottomRow row={bottomRow} Link={Link} />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

Navbar.propTypes = {
  /** Array of the top row of links and dropdowns.  */
  topRow: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  /** Array of the bottom row of links and dropdowns.  */
  bottomRow: PropTypes.array.isRequired,
  /** src for the URL image */
  logo: PropTypes.string.isRequired,
  /** Link component used by the application */
  Link: PropTypes.func.isRequired,
}
