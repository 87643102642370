import React from "react"
import PropTypes from "prop-types"
import { useResponsive } from "../helpers/breakpoints"
import { Box } from "rebass"

export const Text = ({
  mediaPosition,
  subHeadline,
  headline,
  Copy,
  style,
  Cta,
}) => {
  const { isMobile } = useResponsive()

  return (
    <Box
      pr={mediaPosition === "right" && !isMobile ? 40 : 0}
      pl={mediaPosition === "left" && !isMobile ? 40 : 0}
      css={`
        flex: 1;
        ${isMobile ? "" : `max-width: 50%;`}
        ${style};
      `}
    >
      <p>{subHeadline}</p>
      <h2 style={{ color: "inherit" }}>{headline}</h2>
      <Box mt={20} mb={50}>
        <Copy />
      </Box>
      {Cta && <Cta />}
    </Box>
  )
}

Text.propTypes = {
  mediaPosition: PropTypes.string,
  subHeadline: PropTypes.string,
  headline: PropTypes.string,
  Copy: PropTypes.node,
  style: PropTypes.object,
  Cta: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}
