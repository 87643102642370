import React from "react";

export const Select = ({
  options = [],
  label,
  placeholder,
  name,
  onChange,
}) => {
  return (
    <fieldset
      css={`
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border: none;
      `}
    >
      <label>{label}</label>
      <select
        css={`
          display: flex;
          width: 100%;
          border: none;
        `}
        id="pet-select"
        name={name}
        onChange={onChange}
      >
        <option value="" selected disabled hidden>
          {placeholder}
        </option>
        {options.map((opt) => (
          <option value={opt.value}>{opt.text}</option>
        ))}
        {/* <option value="dog">Dog</option>
        <option value="cat">Cat</option>
        <option value="hamster">Hamster</option>
        <option value="parrot">Parrot</option>
        <option value="spider">Spider</option>
        <option value="goldfish">Goldfish</option> */}
      </select>
    </fieldset>
  );
};

export default Select;
