import React from "react"
import PropTypes from "prop-types"
import { Flex, Box as Bx, Image, Heading } from "rebass"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { iconize } from "../helpers/icons"
import { useResponsive } from "../helpers/breakpoints"
import { getRandomInt } from "../helpers/random"

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  li {
    margin: 0;
    font-size: 14px;
  }
`
const UnderlineHover = styled.span`
  &:hover {
    text-decoration: underline;
  }
`
const Box = styled(Bx)`
  max-width: 166px;
`
const SocialLinksList = styled(List)`
  width: 200px;
  display: flex;
  justify-content: space-between;
  li {
    margin-right: 30px;
  }
`

export const Footer = ({ columns, logo, Link }) => {
  const { isMobile } = useResponsive()

  return (
    <Flex width="100%" bg="#f6f3ef" flexDirection="column" px="15px" py="70px">
      <Bx mx={55}>
        <Flex
          justifyContent="space-between"
          flexDirection={isMobile ? "column" : "row"}
          flexWrap="wrap"
        >
          <Bx>
            <Image width="300px" src={logo} />
          </Bx>
          {columns.map(column => {
            return (
              <Box
                key={column.headline + getRandomInt(1000)}
                mt={isMobile ? 20 : 0}
                mb={isMobile ? 20 : 0}
              >
                <Heading fontSize="18px" fontWeight={600}>
                  {column.headline}
                </Heading>
                <List>
                  {column.links.map(link => (
                    <li key={link.text + getRandomInt(1000)}>
                      {link.outbound ? (
                        <UnderlineHover>
                          <a href={link.to}>{link.text}</a>
                        </UnderlineHover>
                      ) : (
                        <UnderlineHover>
                          <Link {...link}>{link.text}</Link>
                        </UnderlineHover>
                      )}
                    </li>
                  ))}
                </List>
              </Box>
            )
          })}
          <Box>
            <Heading fontSize="18px" fontWeight={600}>
              Follow
            </Heading>
            <SocialLinksList>
              <li>
                <a href="https://twitter.com/aspireIQ">
                  <FontAwesomeIcon
                    color={"black"}
                    icon={iconize("twitter")}
                    size="sm"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/aspireiq/">
                  <FontAwesomeIcon
                    color={"black"}
                    icon={iconize("linkedin")}
                    size="sm"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UCPo1m8mU9so3R-4J9L1D00Q">
                  <FontAwesomeIcon
                    color={"black"}
                    icon={iconize("youtube")}
                    size="sm"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/aspireiq/">
                  <FontAwesomeIcon
                    color={"black"}
                    icon={iconize("facebook")}
                    size="sm"
                  />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/aspireiq/">
                  <FontAwesomeIcon
                    color={"black"}
                    icon={iconize("instagram")}
                    size="sm"
                  />
                </a>
              </li>
            </SocialLinksList>
          </Box>
        </Flex>
      </Bx>
      <Bx my="40px" mx="55px">
        <hr
          css={`
            border: 0;
            height: 1px;
            background: #333;
            background-image: linear-gradient(to right, #ccc, #333, #ccc);
            margin-bottom: 15px;
          `}
        />
        <Flex
          justifyContent="space-between"
          css={`
            font-size: 12px;
          `}
        >
          <span>
            <FontAwesomeIcon
              color={"black"}
              icon={iconize("globe")}
              size="xs"
            />{" "}
            United States
          </span>
          <UnderlineHover>
            <a href="https://www.aspireiq.com/terms">Terms of Service</a>
          </UnderlineHover>
          <UnderlineHover>
            <a href="https://www.aspireiq.com/privacy">Privacy Policy</a>
          </UnderlineHover>
          <span>
            © {new Date().getFullYear()} AspireIQ. All rights reserved
          </span>
        </Flex>
      </Bx>
    </Flex>
  )
}

Footer.propTypes = {
  columns: PropTypes.array.isRequired,
  logo: PropTypes.string.isRequired,
  Link: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
}
