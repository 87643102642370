import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Box } from "rebass"
import { Helmet } from "react-helmet"
import { TrackingCodes } from "./TrackingCodes"
import {
  typography,
  Footer,
  ModalController,
  Navbar,
} from "@revfluence/aspire-www-ui"

import "./layout.css"

export const DROPDOWNS = {
  signIn: {
    text: "Sign In",
    options: [
      { text: "Brands", to: "/#" },
      { text: "Creators", to: "/#" },
    ],
  },
  solutions: {
    text: "Solutions",
    options: [
      { text: "Influencer Marketing", to: "/#" },
      { text: "Custom Content", to: "/#" },
      { text: "Fully Managed Campaigns", to: "/#" },
    ],
  },
}

export const NAVIGATION = {
  topRow: [],
  bottomRow: [
    { type: "LINK", text: "Timeline", to: "/timeline" },
    { type: "LINK", text: "Data", to: "/data" },
    { type: "LINK", text: "Strategies", to: "/strategies" },
    { type: "LINK", text: "Q&A", to: "/q-and-a" },
    { type: "LINK", text: "Best Practices", to: "/best-practices" },
    { type: "BUTTON", text: "aspireiq.com", to: "https://www.aspireiq.com" },
  ],
}

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { regex: "/aspireiq-logo-holiday/" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ModalController>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/yzt1kvs.css" />
        <style>{typography.toString()}</style>
      </Helmet>
      <TrackingCodes />
      <div>
        <Box>
          <Navbar
            Link={(props) => <Link id="thisLink" {...props} />}
            logo={data.logo.childImageSharp.fluid.src}
            topRow={NAVIGATION.topRow}
            bottomRow={NAVIGATION.bottomRow}
          />
          <main>{children}</main>
        </Box>
        <Footer
          Link={Link}
          columns={[]}
          logo={data.logo.childImageSharp.fluid.src}
        />
      </div>
    </ModalController>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
