import Typography from "typography";
import { colors } from "../helpers/styles";

const ffProximaNova = ["proxima-nova", "sans-serif"].join(",");
const ffPoppins = ["poppins", "sans-serif"].join(",");

export const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.666,
  headerFontFamily: ["poppins", "sans-serif"],
  bodyFontFamily: ["proxima-nova", "sans-serif"],
  bodyColor: colors.desaturatedBlack,
  headerColor: colors.desaturatedBlack,
  // See below for the full list of options.
  overrideStyles: () => ({
    a: {
      color: "inherit",
      textDecoration: "none",
    },
    h1: {
      fontWeight: "semi-bold",
      fontSize: "46px",
    },
    h2: {
      fontWeight: "semi-bold",
      fontSize: "40px",
    },
    h3: {
      fontWeight: "semi-bold",
      fontSize: "24px",
    },
    h4: {
      fontFamily: ffProximaNova,
      textTransform: "uppercase",
      fontSize: "20px",
      color: colors.desaturatedDark,
    },
    p: {
      fontFamily: ffProximaNova,
      fontWeight: "medium",
      fontSize: "20px",
    },
    blockquote: {
      fontFamily: ffPoppins,
      fontWeight: "medium",
      fontSize: "22px",
    },
  }),
});

export default typography;
