import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { MOBILE_MAX, useResponsive } from "../helpers/breakpoints"
import Container from "../Container"
import { colors } from "../helpers/styles"

const LinksSectionContainer = styled.div`
  width: 100%;
  padding: 50px 0;
  background-color: ${colors.primary};
`
const LinksSectionLinks = styled.div`
  max-width: 550px;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
`
const LinkWrap = styled.div`
  color: ${colors.desaturatedWhite};
`

export const LinksSection = ({ Link, links }) => {
  return (
    <LinksSectionContainer>
      <LinksSectionLinks>
        {links &&
          links.map(link => (
            <LinkWrap>
              <Link to={link.to}>{link.text}</Link>
            </LinkWrap>
          ))}
      </LinksSectionLinks>
    </LinksSectionContainer>
  )
}

LinksSection.propTypes = {
  Link: PropTypes.node,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      to: PropTypes.string,
    })
  ),
}
