import React, { useState, useReducer, Fragment } from "react";
import PropTypes from "prop-types";

import { Modal } from "./Modal";
import EmailModal from "./EmailModal";
import SurveyModal from "./SurveyModal";
import VideoModal from "./VideoModal";
import { EmailContext } from "./EmailContext";
import { ModalContext } from "./ModalContext";

const SUBMIT_EMAIL = "SUBMIT_EMAIL";
const SUBMIT_SURVEY = "SUBMIT_SURVEY";
const OPEN_MODAL = "OPEN_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";

const reducer = (state, action) => {
  switch (action.type) {
    case SUBMIT_EMAIL:
      return { open: true, modalNumber: 1 };
    case SUBMIT_SURVEY:
      return { open: false, modalNumber: 0 };
    case CLOSE_MODAL:
      return { open: false, modalNumber: 0 };
    case OPEN_MODAL:
      return {
        ...state,
        open: true,
        modalNumber: action.modalNumber || 0,
        src: action.src || ""
      };
    default:
      throw new Error();
  }
};

export const ModalController = ({ children }) => {
  const [email, setEmail] = useState("");
  const [{ open, modalNumber, src }, dispatch] = useReducer(reducer, {
    open: false,
    modalNumber: 0,
    src: ""
  });

  const completeEmail = () => {
    dispatch({ type: SUBMIT_EMAIL });
  };

  return (
    <ModalContext.Provider
      value={{
        open,
        modalNumber,
        dispatch,
        src
      }}
    >
      <Modal open={open} dispatch={dispatch}>
        <EmailContext.Provider value={{ email, setEmail }}>
          <Fragment>
            {modalNumber === 0 && <EmailModal onSuccess={completeEmail} />}
            {modalNumber === 1 && <SurveyModal />}
            {modalNumber === 3 && <VideoModal src={src} />}
          </Fragment>
        </EmailContext.Provider>
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalController;

ModalController.propTypes = {
  /** Enum for choosing which side the media is displayed on */
  children: PropTypes.object
};
