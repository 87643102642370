import React from "react"
import PropTypes from "prop-types"
import { Flex } from "rebass"
import { MOBILE_MAX, useResponsive } from "../helpers/breakpoints"
import {} from "../helpers/breakpoints"

export const FeatureHighlightGroup = ({ maxColumnCount = 4, children }) => {
  const rowCount = Math.ceil(children.length / maxColumnCount)
  const { isMobile } = useResponsive()

  const rows = new Array(rowCount).fill(null).map((_, idx) => {
    const newChildren = children.map((c, i) => (
      <Flex
        key={i}
        width={1 / maxColumnCount}
        justifyContent="center"
        css={`
          @media (max-width: ${MOBILE_MAX}px) {
            width: 100%;
          }
        `}
      >
        {c}
      </Flex>
    ))
    return (
      <Flex
        key={idx}
        justifyContent="center"
        className="poopie"
        css={`
          @media (max-width: ${MOBILE_MAX}px) {
            flex-direction: column;
            align-items: center;
          }
        `}
      >
        {newChildren.slice(idx * maxColumnCount, (idx + 1) * maxColumnCount)}
      </Flex>
    )
  })
  return (
    <Flex
      css={`
        width: 100%;
        flex-direction: column;
      `}
    >
      {rows}
    </Flex>
  )
}

FeatureHighlightGroup.propTypes = {
  /**  Text to display */
  maxColumnCount: PropTypes.number,
  /** Feature highlights */
  children: PropTypes.node.isRequired,
}

export default FeatureHighlightGroup
