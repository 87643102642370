import React from "react";
import { Flex } from "rebass";
import { Video } from "../VideoComponent/Video";

export const VideoModal = ({ src }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Video src={src} />
    </Flex>
  );
};

export default VideoModal;
