import React, { useContext } from "react";
import { slide as Menu } from "react-burger-menu";
import { MOBILE_TYPES } from "./Types";
import { ModalContext } from "../Modal/ModalContext";
import { getRandomInt } from "../helpers/random";
import { colors } from "../helpers/styles";

export const MobileNav = ({ items, Link }) => {
  const { dispatch } = useContext(ModalContext);
  return (
    <Menu noTransition={true} right={true} styles={styles}>
      {items.map((item) => (
        <li
          style={{
            margin: "0 10px",
            listStyleType: "none",
            lineHeight: 2,
            fontSize: "0.9rem",
          }}
          key={`${item.type}-${getRandomInt(1000)}`}
        >
          {MOBILE_TYPES(Link, dispatch)[item.type]({
            children: item.text,
            weight: "light",
            color: "white",
            ...item,
          })}
        </li>
      ))}
    </Menu>
  );
};

export default MobileNav;

const styles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: colors.desaturatedBlack,
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: colors.desaturatedWhite,
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: colors.primary,
    color: colors.desaturatedWhite,
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
    zIndex: "100",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: colors.desaturatedWhite,
    padding: "0.8em",
    display: "flex",
    flexDirection: "column",
  },
  bmItem: {
    display: "inline-block",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)",
  },
};
