import React, { useContext } from "react";
import { Flex } from "rebass";
import TYPES from "./Types";
import { getRandomInt } from "../helpers/random";
import { ModalContext } from "../Modal/ModalContext";
import { colors } from "../helpers/styles";

const TopRow = ({ row = [], Link }) => {
  const { dispatch } = useContext(ModalContext);
  return (
    <Flex
      css={`
        height: 40px;
        justify-content: flex-end;
        align-items: center;
      `}
      width="100%"
    >
      {row.map(item => (
        <li
          key={item.text + getRandomInt(1000)}
          style={{
            margin: "0 10px",
            listStyleType: "none"
          }}
        >
          {TYPES(Link, dispatch)[item.type]({
            children: item.text,
            weight: "lighter",
            color: colors.desaturatedBlack,
            size: "14px",
            width: "auto",
            height: "auto",
            ...item
          })}
        </li>
      ))}
    </Flex>
  );
};

export default TopRow;
