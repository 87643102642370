import React from "react"
import Button from "../Button"
import NavLink from "../NavLink"
import Dropdown from "../Dropdown"
import { Text } from "rebass"
import { getRandomInt } from "../helpers/random"

const ButtonType = (dispatch, Link) => props => {
  return props.action ? (
    <Button
      {...props}
      onClick={() => dispatch({ type: props.action.type })}
      Link={Link}
      size="sm"
    />
  ) : (
    <a href={props.to}>
      <Button {...props}></Button>
    </a>
  )
}

const LinkType = Link => props => <NavLink {...props} Link={Link} />

const DropdownType = Link => props => (
  <Dropdown align={"left"} {...props} Link={Link} />
)

const MobileDropDownType = Link => props => (
  <span>
    <Text color="#e0e0e0">{props.children}</Text>

    {props.options.map(option => {
      return (
        <Text
          css={`
            font-size: 14px;
            display: block;
            margin-left: 10px;
            color: white;
          `}
          key={`${option.text}-${getRandomInt(1000)}`}
        >
          <Link to={option.to}>{option.text}</Link>
        </Text>
      )
    })}
  </span>
)
const TYPES = (Link, dispatch) => ({
  BUTTON: ButtonType(dispatch, Link),
  LINK: LinkType(Link),
  DROPDOWN: DropdownType(Link),
})

export const MOBILE_TYPES = (Link, dispatch) => ({
  // TODO: Onclick for actions should be assigned here... but how?
  BUTTON: ButtonType(dispatch, Link),
  LINK: LinkType(Link),
  DROPDOWN: MobileDropDownType(Link),
})

export default TYPES
