import { keyframes } from "styled-components";

export const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px);
  }


  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }


  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export default {
  right: fadeInRight,
  up: fadeInUp
};
