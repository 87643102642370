export default function chilipiper(lead) {
  const {
    email,
    firstName,
    lastName,
    phone,
    company,
    industry,
    CP_Company_Size__c,
    Influencers_Worked_With__c
  } = lead;
  // This will be defined at the time using the useScript hook.
  // eslint-disable-next-line no-undef
  // eslint-disable-next-line no-undef
  ChiliPiper.submit("aspireiq", "website-booking", {
    title: "Thanks! What time works best for a quick call?",
    titleStyle: "Roboto 22px #EA5938",
    lead: {
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      Phone: phone,
      Company: company,
      Industry: industry,
      CP_Company_Size__c: CP_Company_Size__c,
      Influencers_Worked_With__c: Influencers_Worked_With__c
    }
  });
}
