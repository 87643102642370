import React from "react";
import PropTypes from "prop-types";
import { Card as Crd } from "rebass";

export const Card = ({ bg = "white", css = "", children, ...props }) => {
  return (
    <Crd
      css={`
        height: auto;
        background: ${bg};
        ${css}
      `}
      {...props}
    >
      {children}
    </Crd>
  );
};

Card.propTypes = {
  bg: PropTypes.string,
};
