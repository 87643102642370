import React from "react";
import { Box, Flex } from "rebass";
import fades from "./animations";
import { useResponsive } from "../helpers/breakpoints";
import { containerCss, imageStyle } from "./sharedStyles";

export const FivePictureCollage = ({
  Media: [One, Two, Three, Four, Five]
}) => {
  const { isMobile } = useResponsive();
  if (isMobile)
    return (
      <Box
        css={`
          position: relative;
          width: 100%;
          animation: ${fades.right};
          ${containerCss};
        `}
      >
        <Three style={imageStyle} />
      </Box>
    );

  return (
    <Box
      css={`
        display: inline-block;
        width: 558px;
        height: 600px;
        position: relative;
      `}
    >
      {One && (
        <Flex
          css={`
            width: 170px;
            height: 170px;
            top: -20px;
            right: 365px;
            z-index: 0;
            animation: ${fades.right};
            ${containerCss};
          `}
        >
          <One style={imageStyle} />
        </Flex>
      )}
      {Two && (
        <Flex
          css={`
            width: 150px;
            height: 150px;
            top: 15px;
            right: 49px;
            z-index: 2;
            animation: ${fades.right};
            ${containerCss};
          `}
        >
          <Two style={imageStyle} />
        </Flex>
      )}
      {Three && (
        <Flex
          css={`
            width: 380px;
            height: 380px;
            top: 85px;
            right: 100px;
            z-index: 1;
            animation: ${fades.up};
            ${containerCss};
          `}
        >
          <Three style={imageStyle} />
        </Flex>
      )}
      {Four && (
        <Flex
          css={`
            width: 270px;
            height: 270px;
            bottom: 95px;
            left: -40px;
            z-index: 2;
            animation: ${fades.right};
            ${containerCss};
          `}
        >
          <Four style={imageStyle} />
        </Flex>
      )}
      {Five && (
        <Flex
          css={`
            width: 150px;
            height: 150px;
            bottom: 40px;
            right: 40px;
            animation: ${fades.right};
            z-index: 0;
            ${containerCss};
          `}
        >
          <Five style={imageStyle} />
        </Flex>
      )}
    </Box>
  );
};

export default FivePictureCollage;
