import React, { useState } from "react";
import { Flex } from "rebass";
import Target from "./Target";
import Box from "./Box";

export const Dropdown = ({
  text,
  options = [],
  color,
  weight,
  align,
  size,
  Link,
}) => {
  const [state, setState] = useState({
    target: false,
    triggered: false,
  });

  const enterTarget = () => {
    setState({ ...state, triggered: true });
  };

  const exitTarget = () => {
    setState({ ...state, triggered: false });
  };

  const enterBox = () => {
    setState({ ...state, triggered: true });
  };

  const exitBox = () => {
    setState({ ...state, triggered: false });
  };

  return (
    <div>
      <Flex
        css={`
          align-items: center;
        `}
        onMouseEnter={() => enterTarget(true)}
        onMouseLeave={() => exitTarget()}
      >
        <Target
          triggered={state.triggered}
          weight={weight}
          color={color}
          text={text}
          size={size}
        />
      </Flex>
      {state.triggered && (
        <Flex
          fontSize={4}
          width="auto"
          color="white"
          css={`
            position: absolute;
            z-index: 10;
          `}
          onMouseEnter={enterBox}
          onMouseLeave={exitBox}
        >
          <Box align={align} options={options} Link={Link} />
        </Flex>
      )}
    </div>
  );
};
