import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import TYPES from "./Types";
import { getRandomInt } from "../helpers/random";
import { ModalContext } from "../Modal/ModalContext";
import { colors } from "../helpers/styles";

const BottomRow = ({ row = [], Link }) => {
  const { dispatch } = useContext(ModalContext);
  return (
    <Flex
      width="70%"
      css={`
        justify-content: flex-end;
        align-items: center;
      `}
    >
      {row.map(item => (
        <li
          key={item.text + getRandomInt(100)}
          style={{ margin: "0 10px", listStyleType: "none" }}
        >
          {TYPES(Link, dispatch)[item.type]({
            children: item.text,
            color: colors.desaturatedBlack,
            size: "16px",
            width: "auto",
            height: "auto",
            ...item
          })}
        </li>
      ))}
    </Flex>
  );
};

export default BottomRow;

BottomRow.propTypes = {
  /** Array of the top row of links and dropdowns.  */
  row: PropTypes.array.isRequired,
  /** Link component used by the application */
  Link: PropTypes.func.isRequired
};
