import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faTwitter,
  faLinkedin,
  faYoutube,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons"
import {
  faTimes,
  faCopy,
  faComment,
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faLaptop,
  faChartLine,
  faShieldCheck,
  faStar,
  faCoins,
  faThumbsUp,
  faThumbsDown,
  faUsers,
  faGlobe,
  faShareAlt,
  faShareAll,
  faShare,
  faImages,
  faBell,
  faList,
  faSeedling,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faHeadSideBrain,
  faBalanceScale,
  faInfoCircle,
  faMedkit,
  faChild,
  faGraduationCap,
  faGamepad,
  faCalendarAlt,
  faPlaneDeparture,
  faCarSide,
  faCookieBite,
  faBeer,
  faBookOpen,
  faUserPlus,
  faBullhorn,
  faSearchDollar,
  faBrain,
  faFileSignature,
  faTasks,
  faSlidersV,
  faFileExport,
  faUserCog,
  faHandsHelping,
  faClipboardCheck,
  faVideo,
  faUserFriends,
  faComments,
  faFileDownload,
  faMousePointer,
  faTrophy,
  faSearch,
  faSearchPlus,
  faCommentAlt,
  faUserEdit,
  faBullseyeArrow,
  faUsersCrown,
  faClipboardListCheck,
  faListOl,
  faPhotoVideo,
  faUserHeadset,
  faPeopleCarry,
  faStore,
  faHeart,
  faSackDollar,
  faAnalytics,
  faFileChartLine,
  faShapes,
  faChartPie,
  faLightbulb,
  faSmile,
  faExpandArrows,
} from "@fortawesome/pro-light-svg-icons"

import { faCaretUp } from "@fortawesome/free-solid-svg-icons"

export const allowedIcons = {
  twitter: ["fab", faTwitter],
  linkedin: ["fab", faLinkedin],
  youtube: ["fab", faYoutube],
  instagram: ["fab", faInstagram],
  facebook: ["fab", faFacebook],

  analytics: ["fal", faAnalytics],
  "angle-down": ["fal", faAngleDown],
  "angle-left": ["fal", faAngleLeft],
  "angle-right": ["fal", faAngleRight],
  "angle-up": ["fal", faAngleUp],
  "balance-scale": ["fal", faBalanceScale],
  beer: ["fal", faBeer],
  bell: ["fal", faBell],
  "book-open": ["fal", faBookOpen],
  brain: ["fal", faBrain],
  bullhorn: ["fal", faBullhorn],
  "bullseye-arrow": ["fal", faBullseyeArrow],
  "caret-up": ["fas", faCaretUp],
  "calendar-alt": ["fal", faCalendarAlt],
  "car-side": ["fal", faCarSide],
  "chart-line": ["fal", faChartLine],
  "chart-pie": ["fal", faChartPie],
  check: ["fal", faCheck],
  "chevron-down": ["fal", faChevronDown],
  "chevron-right": ["fal", faChevronRight],
  "chevron-left": ["fal", faChevronLeft],
  "chevron-up": ["fal", faChevronUp],
  child: ["fal", faChild],
  "clipboard-check": ["fal", faClipboardCheck],
  "clipboard-list-check": ["fal", faClipboardListCheck],
  coins: ["fal", faCoins],
  comment: ["fal", faComment],
  comments: ["fal", faComments],
  "comment-alt": ["fal", faCommentAlt],
  "cookie-bite": ["fal", faCookieBite],
  copy: ["fal", faCopy],
  "expand-arrows": ["fal", faExpandArrows],
  "file-chart-line": ["fal", faFileChartLine],
  "file-download": ["fal", faFileDownload],
  "file-export": ["fal", faFileExport],
  "file-signature": ["fal", faFileSignature],
  gamepad: ["fal", faGamepad],
  globe: ["fal", faGlobe],
  "graduation-cap": ["fal", faGraduationCap],
  "hands-helping": ["fal", faHandsHelping],
  "head-side-brain": ["fal", faHeadSideBrain],
  heart: ["fal", faHeart],
  images: ["fal", faImages],
  "info-circle": ["fal", faInfoCircle],
  laptop: ["fal", faLaptop],
  lightbulb: ["fal", faLightbulb],
  list: ["fal", faList],
  "list-ol": ["fal", faListOl],
  medkit: ["fal", faMedkit],
  "mouse-pointer": ["fal", faMousePointer],
  "people-carry": ["fal", faPeopleCarry],
  "photo-video": ["fal", faPhotoVideo],
  "plane-departure": ["fal", faPlaneDeparture],
  "sack-dollar": ["fal", faSackDollar],
  search: ["fal", faSearch],
  "search-dollar": ["fal", faSearchDollar],
  "search-plus": ["fal", faSearchPlus],
  seedling: ["fal", faSeedling],
  shapes: ["fal", faShapes],
  share: ["fal", faShare],
  "share-alt": ["fal", faShareAlt],
  "share-all": ["fal", faShareAll],
  "shield-check": ["fal", faShieldCheck],
  "sliders-v": ["fal", faSlidersV],
  smile: ["fal", faSmile],
  star: ["fal", faStar],
  store: ["fal", faStore],
  tasks: ["fal", faTasks],
  times: ["fal", faTimes],
  "thumbs-down": ["fal", faThumbsDown],
  "thumbs-up": ["fal", faThumbsUp],
  trophy: ["fal", faTrophy],
  "user-cog": ["fal", faUserCog],
  "user-edit": ["fal", faUserEdit],
  "user-friends": ["fal", faUserFriends],
  "user-headset": ["fal", faUserHeadset],
  "user-plus": ["fal", faUserPlus],
  users: ["fal", faUsers],
  "users-crown": ["fal", faUsersCrown],
  video: ["fal", faVideo],
}

export const initIcons = () => {
  library.add(
    ...Object.keys(allowedIcons).map(key => {
      return allowedIcons[key][1]
    })
  )
}

export function iconize(string = "") {
  let icon = allowedIcons[string]
  if (!icon) return null

  return [icon[0], string]
}
