import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Text } from "rebass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconize } from "../helpers/icons";
import Chevron from "./Chevron";

const Target = ({ color, weight, size, text, triggered }) => (
  <Fragment>
    <Text
      color={color}
      mx={2}
      css={`
        font-weight: ${weight || "bold"};
        ${size ? `font-size: ${size}` : ""}
      `}
    >
      {text}
    </Text>
    <FontAwesomeIcon
      color={color}
      icon={triggered ? iconize("angle-up") : iconize("angle-down")}
    />
    {triggered && <Chevron />}
  </Fragment>
);

export default Target;

Target.propTypes = {
  color: PropTypes.string.isRequired,
  weight: PropTypes.oneOf(["normal", "bold", "bolder", "lighter"]),
  text: PropTypes.string.isRequired,
  triggered: PropTypes.bool
};
