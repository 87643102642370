import React from "react";
import PropTypes from "prop-types";
import Card from "../Card";
import { getRandomInt } from "../helpers/random";
import { dropShadow } from "../helpers/styles";

const ALIGN = {
  right: "translate(15%)",
  left: "translateX(-45%)",
  center: "translateX(-25%)",
};

const Box = ({ align, options, Link }) => (
  <Card
    css={`
      transform: ${ALIGN[align]};
      color: black;
      padding: 15px 0;
      margin-top: 5px;
      box-shadow: ${dropShadow};
    `}
  >
    <ul
      css={`
        list-style-type: none;
        min-width: 150px;
        margin: 0;
      `}
    >
      {options.map((option) => (
        <li
          key={option.text + getRandomInt(1000)}
          css={`
            line-height: 14px;
            margin-right: 40px;
            font-size: 16px;
            font-weight: 700;
            padding: 15px 30px 15px 30px;
            cursor: pointer;
            &:hover {
              box-shadow: ${dropShadow};
              background: #276ee1;
              color: white;
            }
            transition: all 0.2s ease-in-out;
          `}
        >
          <Link to={option.to}>{option.text}</Link>
        </li>
      ))}
    </ul>
  </Card>
);

export default Box;

Box.propTypes = {
  align: PropTypes.oneOf(["left", "right", "center"]).isRequired,
  options: PropTypes.array.isRequired,
  Link: PropTypes.element,
};
