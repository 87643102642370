export const dropShadow = "2px 13px 15px -1px rgba(0, 0, 0, 0.2)";

export const colors = {
  /** Navy blue */
  primary: "#1F3F8B",
  /** Electric blue */
  primary2: "#276EE1",
  /** Cloud blue */
  primary3: "#E9F0FC",

  /** Fever pink */
  secondary: "#F58984",
  /** Earth pink */
  secondary2: "#FCBBAE",
  /** Supernova pink */
  secondary3: "#FEF0ED",

  /** Deep green */
  tertiary: "#006462",
  /** Yellow */
  tertiary2: "#FFCD5B",
  /** Sand */
  tertiary3: "#F6F3EF",

  /** White */
  desaturatedWhite: "#FFFFFF",
  /** Light grey */
  desaturatedLight: "#F1F4F8",
  /** Bay grey */
  desaturatedDark: "#989EA8",
  /** Black */
  desaturatedBlack: "#000000",
};
