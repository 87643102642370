import React from "react"
import PropTypes from "prop-types"
import { Box } from "rebass"
import { useResponsive } from "../helpers/breakpoints"

export const Media = ({ Media }) => {
  const { isMobile } = useResponsive()
  return (
    <Box
      css={`
        flex: 1;
        overflow: visible;
        ${isMobile ? "" : `max-width: 45%;`}
      `}
    >
      {Media !== undefined ? (
        <Media />
      ) : (
        <Box
          bg="blue"
          css={`
            width: 543px;
            height: 272px;
          `}
        ></Box>
      )}
    </Box>
  )
}

Media.propTypes = {
  Media: PropTypes.func,
}
