import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Flex } from "rebass";
import { Helmet } from "react-helmet";
import { colors, dropShadow } from "../helpers/styles";

export const RequestDemo = ({ script, scriptInitializer, formId }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timeout = setInterval(() => {
      if (window.MktoForms2) {
        setIsLoaded(true);
        clearInterval(timeout);
      }
    }, 200);
  }, []);

  return (
    <Flex
      css={`
        background: ${colors.desaturatedWhite};
        padding: 25px;
        box-shadow: ${dropShadow};
      `}
    >
      <Helmet>
        <script src={script} />
        {isLoaded && <script>{scriptInitializer}</script>}
      </Helmet>
      <form id={formId} />
    </Flex>
  );
};

RequestDemo.propTypes = {
  script: PropTypes.string,
  scriptInitializer: PropTypes.string,
  formId: PropTypes.string,
};
