import React from "react";
import ReactModal from "react-modal";
import { Flex } from "rebass";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconize } from "../helpers/icons";

export const Modal = ({ children, open, dispatch }) => {
  return (
    <div>
      <div>
        <ReactModal
          isOpen={open}
          ariaHideApp={false}
          onRequestClose={() => dispatch({ type: "CLOSE_MODAL" })}
          style={{
            overlay: {
              background: "#e9f0fc90",
              zIndex: 10,
            },
            content: {
              border: "none",
              borderRadius: 0,
              height: "auto",
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
          <Flex justifyContent="flex-end">
            <Button
              size="sm"
              onClick={(x) => dispatch({ type: "CLOSE_MODAL" })}
            >
              <FontAwesomeIcon color="white" icon={iconize("times")} />
            </Button>
          </Flex>
          {children}
        </ReactModal>
      </div>
    </div>
  );
};
