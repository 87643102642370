import React from "react";
import PropTypes from "prop-types";
import { Flex, Box } from "rebass";
import { colors } from "../helpers/styles";

export const Tab = ({ title, copy, isActive, tabIndex, onClick, ...props }) => (
  <Flex
    onClick={onClick}
    css={{
      flexDirection: "column",
      marginBottom: "20px",
      padding: "20px 40px",
      borderRadius: "20px 0 0 20px",
      backgroundColor: isActive ? colors.primary3 : "none",
      color: isActive ? colors.desaturatedBlack : colors.desaturatedWhite,
      cursor: "pointer",
      ":hover": {
        backgroundColor: colors.primary2,
        color: colors.desaturatedWhite,
      },
    }}
    {...props}
  >
    <h3 style={{ margin: "0", fontSize: "30px", color: "inherit" }}>
      <Box
        css={`
          width: 35px;
          display: inline-block;
        `}
      >
        {tabIndex}.
      </Box>{" "}
      {title}
    </h3>
    <Box
      css={`
        padding-left: 43px;
        font-size: 18px;
        line-height: 22px;
      `}
    >
      {copy}
    </Box>
  </Flex>
);

Tab.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.string,
  isActive: PropTypes.bool,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
};
