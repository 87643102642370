export const containerCss = `
  position: absolute;
  object-fit: cover;
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-fill-mode: both;
  backface-visibility: hidden;
  box-shadow: 2px 13px 15px -1px rgba(0, 0, 0, 0.2);
`;

export const imageStyle = {
  objectFit: "cover",
  width: "100%",
  height: "100%"
};
