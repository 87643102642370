import React from "react";
import { Box } from "rebass";

export const Container = ({ children, ...props }) => (
  <Box
    css={`
      width: 100%;
      max-width: 1140px;
      margin: auto;
    `}
    {...props}
  >
    {children}
  </Box>
);
