import React from "react";
import PropTypes from "prop-types";
import { Button as Btn } from "rebass";
import { colors } from "../helpers/styles";

const backgroundColor = ({ light, dark, disabled }) => {
  if (disabled) return colors.desaturatedDark;
  if (light) return colors.desaturatedWhite;
  if (dark) return colors.desaturatedBlack;
  return colors.primary2;
};

const textColor = ({ light, dark, disabled }) => {
  if (light) return colors.primary2;
  if (dark) return colors.desaturatedWhite;
  return colors.desaturatedWhite;
};

const hoverBackgroundColor = ({ light, disabled, hoverBg }) => {
  if (hoverBg === "contrast") return colors.secondary;
  if (light) return "none";
  if (disabled) return colors.desaturatedDark;
  return colors.primary;
};

const padding = ({ size }) => {
  if (size === "sm") return "5px 15px";

  return "15px 25px";
};

const getStyle = ({
  bg,
  hoverBg,
  light = false,
  dark = false,
  disabled = false,
  size,
  type,
}) => {
  if (type === "link") {
    const dominantColor = disabled ? colors.desaturatedDark : colors.primary2;
    const highlightColor = disabled ? colors.desaturatedDark : colors.primary;
    return `
      padding: 0 0 1px 0; // Allow for bottom border on hover
      background: transparent;
      border-radius: 0px;
      color: ${dominantColor};
      letter-spacing: 1px;
      &:hover {
        padding: 0;
        color: ${highlightColor};
        border-bottom: 1px solid  ${highlightColor};
      }
    `;
  }
  return `
      background: ${bg || backgroundColor({ light, dark, disabled })};
      color: ${textColor({ light, dark, disabled })};
      white-space: nowrap;
      padding: ${padding({ size })};
      border-radius: 0;
      letter-spacing: 1px;
      &:hover {
        background: ${hoverBackgroundColor({ light, disabled, hoverBg })};
      }
      `;
};

export const Button = ({
  bg = null,
  hoverBg = null,
  children,
  light = false,
  dark = false,
  disabled = false,
  type = "default",
  size = "md",
  ...props
}) => (
  <Btn
    css={getStyle({ bg, light, dark, disabled, type, size, hoverBg })}
    {...props}
  >
    {children}
  </Btn>
);

Button.propTypes = {
  /** The background color for the button */
  bg: PropTypes.string,
  /** Bool for easily setting the bg to a light default */
  light: PropTypes.bool,
  /** Bool for easily setting the bg to a dark default */
  dark: PropTypes.bool,
  /** Bool to make a button disabled */
  disabled: PropTypes.bool,
  /** For picking the style type of the button */
  type: PropTypes.oneOf(["link", "default", "contrast"]),
  children: PropTypes.node.isRequired,
};
